import React from 'react';

const ProjectGallery = ({ children }) => {
	return (
		<div className="project-gallery page-width">
			<ul>{children}</ul>
		</div>
	);
};

export default ProjectGallery;
