import React from 'react';

const Text = ({ title, text }) => {
	return (
		<div className="page-width align-left">
			<p>{title}</p>
			<p className="projects-description">{text}</p>
		</div>
	);
};

export default Text;
