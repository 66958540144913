import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Menu from '../../components/navigation';
import ProjectsText from '../../components/projects/text';
import ProjecsGallery from '../../components/projects/gallery';
import ProjectItem from '../../components/projects/item';
import Image from '../../components/projects/image';

import logo from '../../images/projects/bernardi/big-image1.svg';
import font from '../../images/projects/bernardi/font.svg';
import website from '../../images/projects/bernardi/website.jpg';
import website2x from '../../images/projects/bernardi/website@2x.jpg';
import flyer from '../../images/projects/bernardi/flyer.jpg';
import flyer2x from '../../images/projects/bernardi/flyer@2x.jpg';
import fb from '../../images/projects/bernardi/fb.jpg';
import fb2x from '../../images/projects/bernardi/fb@2x.jpg';
import rollup from '../../images/projects/bernardi/rollup.jpg';
import rollup2x from '../../images/projects/bernardi/rollup@2x.jpg';
import visu from '../../images/projects/bernardi/visu.jpg';
import visu2x from '../../images/projects/bernardi/visu@2x.jpg';

import insta1 from '../../images/projects/bernardi/insta1.jpg';
import insta12x from '../../images/projects/bernardi/insta1@2x.jpg';
import insta2 from '../../images/projects/bernardi/insta2.jpg';
import insta22x from '../../images/projects/bernardi/insta2@2x.jpg';
import insta3 from '../../images/projects/bernardi/insta3.jpg';
import insta32x from '../../images/projects/bernardi/insta3@2x.jpg';
import insta4 from '../../images/projects/bernardi/insta4.jpg';
import insta42x from '../../images/projects/bernardi/insta4@2x.jpg';

const title = 'Corporate Design für Bernardi Dach & Holzbau';
const text =
	'„Bernardi Dach- und Holzbau“ spezialisiert sich vor allem auf ökologisch nachhaltigen Holzbau in vielerlei Variation. Passend dazu ist ein modernes Gestaltungskonzept mitsamt einem vollständigen Alphabet entstanden. Schrift und Logo sind hierbei in Holzbauoptik gehalten und spiegeln so den unverkennbaren, umweltfreundlichen Unternehmensethos wider. Das Corporate Design findet sowohl im Print- als auch im Webbereich sowie auf Social-Media-Plattformen Anwendung.';

/**
 * Page
 */
const Page = () => {
	return (
		<Layout>
			<SEO title="" />
			<Menu selected="projekte" />
			<ProjectsText text={text} title={title} />
			<div className="page-width align-center"></div>
			<ProjecsGallery>
				<ProjectItem>
					<img src={logo} alt="" />
				</ProjectItem>
				<ProjectItem>
					<Image src={website} src2={website2x} />
				</ProjectItem>
				<ProjectItem>
					<img src={font} alt="" />
				</ProjectItem>
				<ProjectItem>
					<Image src={flyer} src2={flyer2x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={fb} src2={fb2x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={insta1} src2={insta12x} />
					<Image src={insta2} src2={insta22x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={insta3} src2={insta32x} />
					<Image src={insta4} src2={insta42x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={rollup} src2={rollup2x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={visu} src2={visu2x} />
				</ProjectItem>
			</ProjecsGallery>
		</Layout>
	);
};

export default Page;
